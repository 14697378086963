import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from '../../../server';
import {jwtDecode} from 'jwt-decode';

const NotesForm = ({ patient }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [notes, setNotes] = useState('');
  const user = useSelector((state) => state.user.user);
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  const employeeId = decodedToken.id
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [formData, setFormData] = useState({
    fullName: patient?.firstName  + ' ' + patient?.middleName + ' ' +patient?.lastName || '',
    patientId: patient?._id,
    phoneNumber: patient?.phoneNumber || '',
    doctorId : employeeId,
    medicalHistory: '',

  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formData); // fullName, phoneNumber will be logged from patient data
     // Create the payload matching the backend structure
     const payload = {
      patientId: formData.patientId,
      doctorId: formData.doctorId,
      medicalHistory: formData.medicalHistory    // Ensure this field matches 'sex' in backend
    };
    try {
      const response = await axios.post(
        `${server}/api/v2/medical-history/create`,
        payload,
        { withCredentials: true }
      );
      console.log('Submitted Data:', response.data);
      toast.success("Medical History created!");

      // Perform any further actions like navigating to another page
      // navigate('/success'); // Example: navigate to a success page after form submission

      // onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || " Failed to save the data");
    }
   

    // onClose();
  };

  

  // const handleSave = () => {
  //   if (onSave) {
  //     onSave(notes);
  //   }
  // };

  return (
    <div ref={formRef}  className="max-w-md mt-[20px] ml-[20px] mx-auto p-6 rounded-lg shadow-md">
       <form onSubmit={handleSubmit}>
      <textarea
        style={{ width: '900px', height: '300px' }}
        className="p-4 border border-green-500 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-green-500"
        placeholder="Add notes ..."
        value={formData.medicalHistory}
        name="medicalHistory"
        onChange={handleChange}
      />
      <div className="flex justify-end mt-4">
        <button
          className="w-[70px] bg-cyan-500 text-white py-2 rounded-[15px] hover:bg-cyan-600"
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
      </form>
    </div>
  );
};

export default NotesForm;
