import React from 'react';

const Activity = () => {
  return (
    <div className="p-6 bg-white rounded-lg border border-gray-800 w-full max-w-lg">
      <div className="mb-4">
        <h2 className="text-2xl font-bold text-gray-800">Activity</h2>
      </div>
      <div className="flex justify-between">
        
       <div className="relative flex  items-center justify-center w-40 h-40">
   
      <div className="absolute w-24 h-24 bg-white rounded-full z-10"></div>


      <div className="absolute w-40 h-40 bg-gray-600 rounded-full clip-donut"></div>

    
      <div className="absolute w-40 h-40 bg-gray-500 rounded-full clip-donut rotate-90"></div>

  
      <div className="absolute w-40 h-40 bg-gray-400 rounded-full clip-donut rotate-180"></div>

    
      <div className="absolute w-40 h-40 bg-gray-300 rounded-full clip-donut rotate-270"></div>
    </div>
        <div className="w-2/3 mb-10 ml-4">
          <table className="min-w-full">
            <thead>
              <tr className="text-gray-500 font-semibold text-left">
                <th className="py-2">Active Users</th>
                <th className="py-2">Day</th>
                <th className="py-2">Time</th>
                <th className="py-2">Grade</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              <tr className="">
                <td className="py-2">User Name</td>
                <td className="py-2">Mon</td>
                <td className="py-2">3:00</td>
                <td className="py-2 ">A</td>
              </tr>
              <tr className="">
                <td className="py-2">User Name</td>
                <td className="py-2">Thu</td>
                <td className="py-2 border-gray">5:30</td>
                <td className="py-2 ">B</td>
              </tr>
              <tr className="">
                <td className="py-2">User Name</td>
                <td className="py-2">Wed</td>
                <td className="py-2">9:30</td>
                <td className="py-2">C</td>
              </tr>
             
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Activity;
