import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="flex h-screen">
      <div className="w-48 sm:w-32 md:w-48 lg:w-48 py-32 bg-cyan-500">

        <NavLink
          to="/reception"
          className={({ isActive }) =>
            isActive ? "block p-4 mb-2 text-center bg-white" : "block p-4 mb-2 text-center bg-cyan-600"
          }
        >
          <span className={({ isActive }) => 
            isActive ? "text-cyan-600 text-sm font-medium" : "text-white text-sm font-medium"
          }>
            Dash board
          </span>
        </NavLink>
        <NavLink
          to="/appointments"
          className={({ isActive }) =>
            isActive ? "block p-4 mb-2 text-center bg-white" : "block p-4 mb-2 text-center bg-cyan-600"
          }
        >
          <span className={({ isActive }) => 
            isActive ? "text-cyan-600 text-sm font-medium" : "text-white text-sm font-medium"
          }>
            Appointments
          </span>
        </NavLink>
        <NavLink
          to="/addPatient"
          className={({ isActive }) =>
            isActive ? "block p-4 mb-2 text-center bg-white" : "block p-4 mb-2 text-center bg-cyan-600"
          }
        >
          <span className={({ isActive }) => 
            isActive ? "text-cyan-600 text-sm font-medium" : "text-white text-sm font-medium"
          }>
            Patient
          </span>
        </NavLink>   
      </div>
    </div>
  );
};

export default Sidebar;
