import React, { useState } from 'react';

const tabs = [
  { id: 'previous', label: 'Previous Treatment' },
  { id: 'history', label: 'Payment History' },
  { id: 'upcoming', label: 'Upcoming Payments' },
];

const treatments = [
  { date: '25 Nov \'19', time: '09.00 - 10.00', treatment: 'Braces phase 1', dentist: 'Dr Adam H.', nurse: 'Jessica', price: '165$', status: 'Paid' },
  { date: '18 Oct \'19', time: '09.00 - 10.00', treatment: 'Root canal prep', dentist: 'Dr Adam H.', nurse: 'Jessica', price: '352$', status: 'Paid' },
];

const ClinicPage = () => {
  const [activeTab, setActiveTab] = useState('previous');

  const renderContent = () => {
    switch (activeTab) {
      case 'previous':
        return treatments.map((item, index) => (
          <div key={index} className="flex   bg-white rounded-lg shadow-md my-4">
            <div>
              <div className="text-sm font-bold">{item.date}</div>
              <div className="text-sm text-gray-500">{item.time}</div>
            </div>
            <div className="text-gray-500 ml-6">
              <div>Treatment</div>
              <div className="text-gray-700 font-semibold">{item.treatment}</div>
            </div>
            <div className="text-gray-500 ml-8">
              <div>Dentist</div>
              <div className="text-gray-700  font-semibold">{item.dentist}</div>
            </div>
            <div className="text-gray-500 ml-6">
              <div>Nurse</div>
              <div className="text-gray-700 font-semibold">{item.nurse}</div>
            </div>
          </div>
        ));
      case 'history':
        return treatments.map((item, index) => (
          <div key={index} className="flex justify-between bg-white rounded-lg shadow-md my-4">
            <div className="text-gray-500">
              <div >Paid on </div>
              <div className="text-black">{item.date}</div>
            </div>
            <div className="text-gray-500">
              <div>Treatment</div>
              <div className="text-black">{item.treatment}</div>
            </div>
            <div className="text-gray-500">
              <div>Dentist</div>
              <div className="text-black">{item.dentist}</div>
            </div>
            <div className="text-gray-500">
              <div>Price</div>
              <div className="font-semibold text-green-500">{item.price}</div>
            </div>
          </div>
        ));
      case 'upcoming':
        return treatments.map((item, index) => (
          <div key={index} className="flex justify-between  bg-white rounded-lg shadow-md my-4">
            <div className='text-gray-500'>
              <div >Due </div>
              <div className="text-black">{item.date}</div>
            </div>
            <div className="text-gray-500">
              <div>Treatment</div>
              <div className="text-black">{item.treatment}</div>
            </div>
            <div className="text-gray-500">
              <div>Dentist</div>
              <div className="text-black">{item.dentist}</div>
            </div>
            <div className="text-gray-500">
              <div>Price</div>
              <div className="font-semibold text-red-500">{item.price}</div>
            </div>
            <div className="text-black-400 border w-[70px] bg-[#D5FAFF] rounded-[15px] "> <p className='mt-2 ml-2'>Invoice</p></div>
          </div>
        ));
      default:
        return null;
    }
  };

  return (
    <div className="p-2 mt-[26px]  w-[430px] ml-6 bg-gray-50 rounded-[15px] h-[260px] overflow-y-hidden border border-[#00B5CE]">
      <div className="flex space-x-4  border-gray-200 mb-8">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`px-2  ${activeTab === tab.id ? 'h-[49px] w-[120px] text-black bg-[#00B5CE] rounded-[15px] text-[14px] text-left ' : 'text-gray-500  text-[14px] text-left'}`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div>
        <h2 className="text-lg font-bold-300 ">Root Canal Treatment</h2>
        <div>{renderContent()}</div>
      </div>
    </div>
  );
};

export default ClinicPage;
