import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { server } from '../../server';
import { fetchUser } from '../../Redux/actions/user';
import axios from 'axios';

const fetchEmployee = async (clinicId) => {
  try {
    const response = await axios.get(`${server}/api/v2/employee/byclinic/${clinicId}`);
    console.log('API Response:', response.data); // Log to ensure correct data
    return response.data.data; // Return only the array of employees
  } catch (error) {
    console.log('Error fetching employees', error);
    throw error;
  }
};

const SearchEmployee = ({ onPlusClick, onEmployeeClick }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    const loadEmployees = async () => {
      if (user && user.clinicId && user.clinicId.length > 0) {
        const clinicId = user.clinicId[0]?.clinic;

        if (clinicId) {
          try {
            const data = await fetchEmployee(clinicId);
            console.log('Fetched Employees:', data); // Ensure data is correct
            setEmployees(data);
          } catch (error) {
            setError('Failed to fetch employees');
            console.error('Fetch Error:', error);
          }
        } else {
          setError('Clinic ID is not valid');
        }
      } else {
        setError('No valid clinic ID found');
      }
    };

    if (user && user.clinicId && user.clinicId.length > 0) {
      loadEmployees();
    }
  }, [user]);

  return (
    <div className="p-4">
      <div className="flex">
        <div className="text-black text-lg font-bold mb-6">Employee</div>
        <button
          onClick={onPlusClick}
          className="flex items-center justify-center w-6 h-6 ml-28 bg-black rounded-full text-white text-2xl"
        >
          <p className='mt-[-7px]'>+</p>
        </button>
      </div>
      <div className="mb-4 border border-gray-300 rounded-xl">
        <input
          type="text"
          placeholder="Search Employee"
          className="w-full p-2"
        />
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="space-y-4 w-52">
        {employees.length > 0 ? (
          employees.map((employee) => {
            const fullName = [employee.firstName, employee.middleName, employee.lastName].filter(Boolean).join(' ');
            return (
              <div
                key={employee._id} // Ensure `_id` is unique
                className="flex items-center p-2 bg-gray-200 rounded-md cursor-pointer"
                onClick={() => onEmployeeClick(employee)}
              >
                <span className="bg-gray-300 text-center text-gray-800 w-8 h-8 rounded-full">
                  {employee.firstName ? employee.firstName[0] : 'N/A'}
                </span>
                <span className="ml-4 text-gray-700">{fullName}</span>
              </div>
            );
          })
        ) : (
          <div className="text-gray-500">No employees found</div>
        )}
      </div>
    </div>
  );
};

export default SearchEmployee;
