import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
  const data = {
    labels: ['Positive', 'Neutral', 'Negative'],
    datasets: [
      {
        data: [43,32 ,25 ],
        backgroundColor: [ '#2D2D2D', '#00A5CF','#00E0FF'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
    },
  };

  return (
    <div className="flex flex-col items-center bg-gray-100 py-8">
      <h2 className="text-xl font-bold mb-4">patient satisfaction</h2>
      <div className="relative w-48 h-48">
        <Pie data={data} options={options} />
       
        <div className="absolute top-[25%] left-[0%] text-sm font-bold text-black">25%</div>
        <div className="absolute top-[5%] right-[0%] text-sm font-bold text-black">32%</div>
        <div className="absolute bottom-[0%] right-[-5%] text-sm font-bold text-black">43%</div>
      </div>
      <div className="flex mt-4 space-x-6">
        <div className="flex items-center">
          <div className="w-3 h-3 bg-[#00E0FF] rounded-full mr-2"></div> {/* Reduced size */}
          <span className="text-sm">positive</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 bg-[#00A5CF] rounded-full mr-2"></div> {/* Reduced size */}
          <span className="text-sm">Neutral</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 bg-[#2D2D2D] rounded-full mr-2"></div> {/* Reduced size */}
          <span className="text-sm">Negative</span>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
