import React, { useState } from 'react';
import ProfileCard from './patientCard';
import NotesSection from './noteSession';
import TreatmentHistory from './treatment';
import DocumentsSection from './docUment';
import FileUpload from './fileUpload';

const PatientProfile = ({ patient, isSearchVisible }) => {
  const [isFileUploadVisible, setIsFileUploadVisible] = useState(false);

  const handleAddFileClick = () => {
    console.log("Add File button clicked");
    setIsFileUploadVisible(true);
  };

  return (
    <div
      className={`h-screen mt-[40px] ${
        isSearchVisible ? 'ml-[-750px]' : 'ml-12'
      } transition-all duration-300 ease-in-out`}
    >
      <ProfileCard patient={patient} />

      <NotesSection />

      <TreatmentHistory />

      <div className='flex mt-4'>
        {!isFileUploadVisible ? (
          <DocumentsSection onAddFileClick={handleAddFileClick} />
        ) : (
          <FileUpload />
        )}
      </div>
    </div>
  );
};

export default PatientProfile;
