import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from '../../../server';
import {jwtDecode} from 'jwt-decode';



const AddAppointmentForm = ({ onClose, patient }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  const employeeId = decodedToken.id
  const [formData, setFormData] = useState({
    fullName: patient?.firstName  + ' ' + patient?.middleName + ' ' +patient?.lastName || '',
    patientId: patient?._id,
    phoneNumber: patient?.phoneNumber || '',
    clinicId: user.clinicId[0].clinic || '', // Set clinicId from props,
    appointmentDate: '',
    appointmentTime: '',
    employee: employeeId,
    status: "scheduled",

  });

 
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formData); // fullName, phoneNumber will be logged from patient data
     // Create the payload matching the backend structure
     const payload = {
      patientId: formData.patientId,
      clinicId: formData.clinicId,
      appointmentDate: formData.appointmentDate,
      appointmentTime: formData.appointmentTime, // Ensure this field matches 'sex' in backend
      employee: formData.employee,
      status: formData.status, 
    };

    try {
      const response = await axios.post(
        `${server}/api/v2/appointments/create`,
        payload,
        { withCredentials: true }
      );
      console.log('Submitted Data:', response.data);
      toast.success("Apointemnt booked Successfully!");

      // Perform any further actions like navigating to another page
      // navigate('/success'); // Example: navigate to a success page after form submission

      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || "Registration Failed");
    }
   

    onClose();
  };

  const handleClickOutside = (e) => {
    if (formRef.current && !formRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={formRef} className="bg-white rounded-2xl p-6 shadow-lg w-[600px] relative">
      {/* Close button */}
     
      <h2 className="text-lg font-semibold text-gray-700 mb-4">Add an appointment</h2>
      <button
        type="button"
        onClick={onClose}
        className="absolute top-2 font-bold text-[30px] right-4 text-gray-600 hover:text-gray-900 focus:outline-none"
      >
        &times;
      </button>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
            placeholder="Full name"
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
            placeholder="Phone number"
          />
        </div>
        <div className="flex justify-between mb-4">
          <div className="flex-1 mr-2">
            <input
              type="date"
              name="appointmentDate"
              value={formData.appointmentDate}
              onChange={handleChange}
              placeholder="Add Date"
              className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
            />
          </div>
          <div className="flex-1 ml-2">
            <input
              type="time"
              name="appointmentTime"
              value={formData.appointmentTime}
              onChange={handleChange}
              className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-6 py-2 bg-cyan-500 text-white font-medium rounded-lg shadow hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-opacity-50"
          >
            + Add Appointment
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAppointmentForm;
