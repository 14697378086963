import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import store from './Redux/store.js';
import { fetchUser } from './Redux/actions/user.js';

import SignInForm from './pages/auth/signIn.jsx';
import Dashboard from './pages/superAdmin/dashboard.jsx';
import Management from './pages/userManagement/approveReject.jsx';
import SignUpForm from './pages/auth/signUp.jsx';
import Employee from './pages/employee/employee.jsx';
import AdminSignUpForm from './pages/superAdmin/superAdmin.jsx';
import ReceptionDashboard from './pages/reception/reception.jsx';
import ClinicAdminDashboard from './pages/clinic/clinicAdminDashboard.jsx';
import RescheduleAppointment from './pages/reception/rescheduleAppointment.jsx';
import AllAppointments from './pages/clinic/allAppointments.jsx';
import AllServices from './pages/clinic/lisOfServices.jsx';
import PatientsDetail from './pages/reception/patientsDetail.jsx';
import PatientDetail from './pages/clinic/patientdetail.jsx';
import ProtectedRoute from './Routes/protectedRoutes.js';
import Appointments from './pages/clinic/appointments.jsx';
import Layout from './components/layout/layout.jsx'; 

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => {
    store.dispatch(fetchUser());
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<SignInForm />} />
          
          <Route path="/dashboard" element={
            <ProtectedRoute allowedRoles={['Doctor']}>
              <Layout ><Dashboard /></Layout>
            </ProtectedRoute>
          } />

          <Route path="/management" element={<Layout ><Management /></Layout>} />

          <Route path='/signUp' element={<Layout><SignUpForm /></Layout>} />

          <Route path='/employee' element={<Layout ><Employee /></Layout>} />

          <Route path='/adminSignUp' element={<Layout ><AdminSignUpForm /></Layout>} />




              {/* reception users dashboard */}
          <Route path='/reception' element={
            <ProtectedRoute allowedRoles={['Reception']}>
              <Layout><ReceptionDashboard /></Layout>
           </ProtectedRoute>
          } />

          {/* clinic admin dashboard */}

          <Route path='/clinic' element={
            <ProtectedRoute allowedRoles={['Doctor']}>
            <Layout ><ClinicAdminDashboard /></Layout>
            </ProtectedRoute>} />


            {/* add patient for patient role  */}

          <Route path='/addPatient' element={
            <ProtectedRoute allowedRoles={['Reception']}>
              <Layout ><PatientsDetail /></Layout>
           </ProtectedRoute>
          } />

         
          <Route path='/patientDetail' element={<Layout><PatientDetail /></Layout>} />

          <Route path='/allappointments' element={<Layout><AllAppointments /></Layout>} />
          <Route path='/appointments' element={<Layout><Appointments /></Layout>} />
          <Route path='/allService' element={<Layout ><AllServices /></Layout>} />

        </Routes>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
      </div>
    </Router>
  );
}

export default App;
