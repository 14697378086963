import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FilterOptions from './filterService'; 
import { server } from '../../../server';

const AllServiceList = () => {
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch services from the backend
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${server}/api/v2/clinic-service/get-all-services`); // Replace with your backend URL
        setServices(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching services:', error);
        setIsLoading(false);
      }
    };

    fetchServices();
  }, []);

  const [isAppointmentVisible, setIsAppointmentVisible] = useState(false);

  const handleButtonClick = () => {
    setIsAppointmentVisible(true);
  };

  const closeForm = () => {
    setIsAppointmentVisible(false);
  };

  if (isLoading) {
    return <div>Loading...</div>; // Loading state
  }

  return (
    <div className="w-full mt-[-462px] ml-[18%] overflow-x-hidden">
      <div className='flex justify-between'>
        <p className='text-gray-400 whitespace-nowrap'>
          Showing <span className='text-black'>{services.length} services</span>
        </p>
        <FilterOptions />
      </div>

      <div className="w-full bg-white shadow sm:rounded-lg">
        <table className="w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 w-[50%]">
            <tr>
              <th className="px-6 w-[40%] py-3 bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">NAME OF THE SERVICE</th>
              <th className="py-3 bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">PRICE</th>
              <th className="py-3 bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {services.map((service, index) => (
              <tr key={index}>
                <td className="px-6 w-[40%] py-4 whitespace-nowrap text-sm text-gray-500">{service.serviceName}</td>
                <td className="py-4 whitespace-nowrap text-sm text-gray-500">{service.servicePrice}</td>
                <td className="py-4 whitespace-nowrap text-sm text-gray-500">{service.serviceStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllServiceList;
