import React, { useState } from 'react';


const appointments = [
    {
        test: "Upper Abdomen General – Test Code 2705",
        time: "10:13 - 12:00",
    },
    {

        test: "Gynecologic Disorders",
        time: "10:13 - 12:00",
    },
    {

        test: "Upper Abdomen General – Test Code 2705",
        time: "10:13 - 12:00",
    },
    {

        test: "Upper Abdomen General – Test Code 2705",
        time: "10:13 - 12:00",
    },
    {

        test: "Upper Abdomen General – Test Code 2705",
        time: '10:13 - 12:00',
    }
];

const AllAppointments = () => {
    const [isAppointmentVisible, setIsAppointmentVisible] = useState(false);

    const handleButtonClick = () => {
        setIsAppointmentVisible(true);
    };
    const closeForm = () => {

        setIsAppointmentVisible(false);
    };

    return (
        <div className=" mt-[40px] ml-[-730px]">




            <div className=" shadow  sm:rounded-lg">
                <table className="w-[600px] divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr >
                            <th className="px-6  py-3 bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">TIME</th>
                            <th className="px-6 mr-[300px] py-3 bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">TEST</th>

                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {appointments.map((appointment, index) => (
                            <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{appointment.time}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{appointment.test}</td>


                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
    );
};

export default AllAppointments;
