import React, { useState } from 'react';
import Sidebar from '../../components/clinic/sideBar';
import SearchEmployee from '../../components/employee/searchEmployee';
import EmployeeForm from '../../components/employee/addEmployee'; // For adding a new employee
import EditEmployeeForm from '../../components/employee/editEmployee'; // For editing an existing employee

const Employee = () => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);

  const handlePlusClick = () => {
    setIsFormVisible(true);
    setIsEditFormVisible(false);
    setSelectedEmployee(null);
  };

  const handleEditClick = () => {
    setIsEditFormVisible(true);
    setIsFormVisible(false);
  };

  const handleEmployeeClick = (employee) => {
    const fullName = `${employee.firstName} ${employee.middleName} ${employee.lastName}`;
    setSelectedEmployee({
      fullName,
      email: employee.email,
      phone: employee.phoneNumber,
      address1: employee.address,
      status: 'employee',
      role: employee.role,
      availability: employee.availability,
       employeeId: employee._id, // Assuming the employee's ID from the database is _id
    clinicId: employee.clinicId[0].clinic,
    });
    setIsFormVisible(false);
    setIsEditFormVisible(false);
  };

  const handleCloseForm = () => {
    setIsFormVisible(false);
    setIsEditFormVisible(false);
  };

  return (
    <div className="relative flex">
      <Sidebar />
      <SearchEmployee onPlusClick={handlePlusClick} onEmployeeClick={handleEmployeeClick} />
      <div className={`flex-1 p-6 ${isFormVisible || isEditFormVisible ? 'opacity-50' : ''}`}>
        {selectedEmployee ? (
          <div className="p-2 rounded mt-[60px] border-gray-700 shadow-lg">
            <h2 className="text-xl font-bold mb-4">Content and ID Info</h2>
            <div className="flex">
              <div className="mb-6">
                <p className="text-md">Name:</p>
                <p className="text-cyan-500 underline">{selectedEmployee.fullName}</p>
              </div>
              <div className="mb-6 ml-28">
                <p className="text-md">Email:</p>
                <p className="text-cyan-500 underline">{selectedEmployee.email}</p>
              </div>
              <div className="mb-6 ml-28">
                <p className="text-md">Phone:</p>
                <p className="text-cyan-500 underline">{selectedEmployee.phone}</p>
              </div>
            </div>
            <div className="flex">
              <div className="mb-6">
                <p className="text-md">Address Line 1:</p>
                <p className="text-cyan-500">{selectedEmployee.address1}</p>
              </div>
            </div>
            <h2 className="text-xl font-bold mb-4">Employment Status</h2>
            <p className="text-cyan-500 ml-[50px] mb-4">Employee</p>
            <div className="flex">
              <div className="mb-6">
                <p className="text-md">Status:</p>
                <p className="text-cyan-500">{selectedEmployee.status}</p>
              </div>
              <div className="mb-6 ml-28">
                <p className="text-md">Assign Role:</p>
                <p className="text-cyan-500">{selectedEmployee.role}</p>
              </div>
            </div>
            <button
              className="bg-cyan-500 text-white py-2 px-4 ml-[580px] rounded hover:bg-blue-600"
              onClick={handleEditClick}
            >
              Edit
            </button>
          </div>
        ) : (
          <div className="text-gray-500">Select an employee to view their details.</div>
        )}
      </div>
      {(isFormVisible || isEditFormVisible) && (
       <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
       {isFormVisible ? (
         <EmployeeForm onClose={handleCloseForm} />
       ) : (
         <EditEmployeeForm 
           onClose={handleCloseForm} 
           selectedEmployee={selectedEmployee} // Pass the selectedEmployee data
         />
       )}
     </div>
      )}
    </div>
  );
};

export default Employee;
