import React from 'react';

const Widgets = () => {
  return (
    <div className="flex mb-8 gap-8">
      <div className="bg-gray-200 p-4 h-20 rounded-xl ">
        <h3 className="text-lg">Register patient</h3>
        <div className='flex '>
        <div className="text-2xl font-bold ">2.352M</div>
        <div className=" ml-8 mt-1">+11%</div>
        </div>
        
      </div>
      <div className="bg-gray-200 p-4 h-20 rounded-lg ">
        <h3 className="text-lg">Collected Payment</h3>
        <div className='flex'>
        <div className="text-2xl font-bold">2.352M</div>
        <div className=" ml-8 mt-1">+11%</div>
        </div>
        
      </div>
      <div className="bg-gray-200 p-4 h-20 w-48 rounded-lg ">
        
        <h3 className="text-lg">WORKER</h3>
        <div className="text-2xl font-bold">150</div>
      </div>
      <div className="bg-gray-200 px-4  h-20 w-48 rounded-lg ">
        <h3 className="text-sm">MON</h3>
        <div className="text-xl ">08:54:58</div>
        <div className="text-gray-500">Let get moving</div>
      </div>
    </div>
  );
};

export default Widgets;
