import React, { useState, useEffect } from 'react';
import FilterOptions from './filter';
import axios from 'axios';
import { server } from '../../server';

const AllAppointmentList = () => {
  const [appointments, setAppointments] = useState([]);
  const [isAppointmentVisible, setIsAppointmentVisible] = useState(false);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await axios.get(`${server}/api/v2/appointments/all`); // Fetch appointments from backend
        setAppointments(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };

    fetchAppointments();
  }, []);

  const handleButtonClick = () => {
    setIsAppointmentVisible(true);
  };

  const closeForm = () => {
    setIsAppointmentVisible(false);
  };

  const handleRowClick = (appointment) => {
    console.log('Row clicked:', appointment);
  };

  return (
    <div className=" w-full mt-4 ml-[210px] mt-[-500px] overflow-x-hidden">
      {/* Heading and Filter Options */}
      <div className='flex justify-between'>
        <p className='text-gray-400'>
          Showing <span className='text-black'>{appointments.length} appointments</span>
        </p>
        <FilterOptions />
      </div>

      <div className="bg-white shadow sm:rounded-lg overflow-y-scroll">
        <table className="w-full divide-y divide-gray-100">
          <thead className="">
            <tr>
              <th className="px-2 py-3 bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
              <th className="py-3 bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Patient</th> 
              <th className=" py-3 bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className=" py-3 bg-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {appointments.map((appointment, index) => (
              <tr key={index}
                onClick={() => handleRowClick(appointment)}
                className='cursor-pointer hover:bg-gray-300'
              >
                {/* Accessing the appointment time and formatting date */}
                <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                  {appointment.appointmentTime || 'N/A'}
                </td>
                {/* Patient's name */}
                <td className=" py-4 whitespace-nowrap text-sm text-gray-500">
                  {appointment.patientId ? `${appointment.patientId.firstName} ${appointment.patientId.lastName}` : 'N/A'}
                </td>
                {/* Clinic name */}
                {/* <td className=" py-4 whitespace-nowrap text-sm text-gray-500">
                  {appointment.clinicId ? appointment.clinicId.clinicName : 'N/A'}
                </td> */}
                {/* Appointment date */}
                <td className=" py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(appointment.appointmentDate).toLocaleDateString() || 'N/A'}
                </td>
                {/* Appointment status */}
                <td className=" py-4 whitespace-nowrap text-sm text-gray-500">
                  {appointment.status || 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllAppointmentList;
