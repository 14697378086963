import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import image from '../../../assets/man.png';
import { fetchUser } from '../../../Redux/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { server } from "../../../server";
import axios from 'axios';
import { toast } from 'react-toastify';

const AddPatientForm = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    phoneNumber: '',
    email: '',
    country: 'Ethiopia',
    city: '',
    clinicId: user.clinicId[0].clinic || '', // Set clinicId from props
  });

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create formatted address array
    const addressArray = [
      {
        address_line_1: '', // Replace with actual data if applicable
        city: formData.city,
        state: '', // Add state if applicable
        postal_code: '', // Add postal code if applicable
        country: formData.country
      }
    ];

    // Create the payload matching the backend structure
    const payload = {
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      sex: formData.gender, // Ensure this field matches 'sex' in backend
      dateOfBirth: formData.dateOfBirth,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      address: addressArray,
      clinicId: formData.clinicId // Assuming clinicId is a single value, not an array
    };

    try {
      const response = await axios.post(
        `${server}/api/v2/patients/create`,
        payload,
        { withCredentials: true }
      );
      console.log('Submitted Data:', response.data);
      toast.success("Patient Registered Successfully!");

      // Perform any further actions like navigating to another page
      // navigate('/success'); // Example: navigate to a success page after form submission

      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || "Registration Failed");
    }
  };


  const handleCancel = () => {
    onClose();
  };

  const handleClickOutside = (e) => {
    if (formRef.current && !formRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex justify-start items-center h-screen ">
      <div ref={formRef} className="bg-white h-[500px] mt-8 rounded-[30px] shadow-lg w-[800px] overflow-hidden flex flex-col">
        <h2 className="text-xl font-semibold ml-4 mt-6">Add PATIENT</h2>
        <button
        type="button"
        onClick={onClose}
        className="absolute top-[80px] font-bold text-[30px] ml-[750px]   text-gray-600 hover:text-gray-900 focus:outline-none"
      >
        &times;
      </button>
        <div className='w-[80px] h-[120px] ml-8 mt-4 bg-gray-200 rounded-full '>
          <img src={image} alt="profile" className='w-[60px] mt-[-2px] ml-2 ' /></div>

        <div className='flex border bg-gray-300 w-[160px] h-[35px] border-gray-300 rounded-[15px] ml-8 mt-8'>

          <img src={image} alt="profile" className='w-[20px] rounded-full  w-6 h-6 ml-2 mb-2' />
          <h2 className='ml-4 mb-2'> Profile</h2>
        </div>

        <div className="flex h-full mt-[-80px] overflow-y-auto ml-[200px] ">
          <div className="border-l-2 border-gray-300 h-screen mr-8"></div>
          <form onSubmit={handleSubmit} className="">
            {/* Form fields go here */}
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Patient Details
              </label>
              <div className="flex gap-4 mt-1">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="p-2 block w-full border border-gray-300 rounded-[15px]"
                  placeholder="First Name"
                />
                <input
                  type="text"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                  className="p-2 block w-full border border-gray-300 rounded-[15px]"
                  placeholder="Father Name"
                />
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="p-2 block w-full border border-gray-300 rounded-[15px]"
                  placeholder="G. Father Name"
                />
              </div>
            </div>

            {/* second row */}
            <div className="mb-4">

              <div className="flex gap-4 mt-1">
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Gender
                  </label>
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="p-2 block w-full border border-gray-300 rounded-[15px]"
                  >
                    <option value="" disabled>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    className="p-2 block w-full border border-gray-300 rounded-[15px]"
                  />
                </div>
              </div>
            </div>


            {/* third row */}

            <div className="mb-4">

              <div className="flex gap-4 mt-5">
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="p-2 block w-full border border-gray-300 rounded-[15px]"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="p-2 block w-full border border-gray-300 rounded-[15px]"
                    placeholder="Email Address"
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <select
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-[15px]"
              >
                <option value="Ethiopia">Ethiopia</option>
              </select>
            </div>
            <div className="mb-4">
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="mt-1 p-2 block w-full border border-gray-300 rounded-[15px]"
                placeholder="City"
              />
            </div>
            {/* <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  State
                </label>
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-[15px]"
                >
                  <option value="">Select</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Price
                </label>
                <input
                  type="text"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-[15px]"
                  placeholder="Enter appointment price"
                />
              </div> */}
          </form>
        </div>
        <div className="p-4 bg-white border-t flex justify-end space-x-4">
          <button
            type="button"
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-cyan-500 text-white rounded-md"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPatientForm;
